@use '/src/scss/angular-materials/nextech-palettes/bw-common-palettes.scss' as *;
@use '/src/scss/angular-materials/nlm-colors.scss';

// App specific styles

// ************************************************
// Main layout left sidebar with icons
// ************************************************

$nx-nav-bg: #2B2E36;
$nx-nav-hover: #333740;
$nx-nav-sub-bg: #202228;
$nx-light-grey-footer: #f0f0f0;
$nx-dark-grey-search-placeholder: rgba(0, 0, 0, 0.60);
$nx-link-color: #257DBF;

$nx-blue-color: #337ab7;
$nx-red-color: #f86c6b;
$nx-green-color: #749F74;
$nx-light-blue-color: #66bae5;

$nx-custom-secondary-gray-color: #c8ced3;
$nx-black-color-opacity: rgba(0, 0, 0, 0.5);


// ************************************************
// Dashboard card action 
// ************************************************
$nx-mat-option-active: #E9F5FB;

.card-1:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.table-hover-1 tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: whitesmoke;
}

.container2 {
    width: 98%;
    height: 98%;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.fluidText {
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}


.breadcrumb {
    padding-bottom: 3px !important;
    margin-bottom: 3px !important;
}

.container-fluid {
    padding: 3px !important;
    margin: 3px !important;
}

.card-body {
    padding: 10px !important;
}

//menu text color
.sidebar .nav-link {
    color: whitesmoke;
}


//icon color active
.sidebar .nav-link.active .nav-icon {
    color: whitesmoke;
}

//menu icon
.fa-lg {
    font-size: 1.33333333em !important;
    line-height: .75em;
    vertical-align: -15%;
}

body {
    min-width: 320px !important;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, noto sans, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
}


.sidebar {
    font-size: 13px !important;
}

.settings-menu ul ul li {
    float: none;
    width: auto;
    padding: 5px 0;
    font-weight: 400;
    font-size: 15px !important;
    position: relative;
    padding-left: 22px;
}


.ngx-datatable.material {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: #c8ced3 !important;
    background: #fff !important;
    box-shadow: none !important;

}

.ngx-datatable.material .datatable-header .datatable-header-cell {
    text-align: center !important;
    padding: 0.9rem 1.2rem;
    background-color: whitesmoke !important;
    border-right: #cdcdce;
    border-right-width: 1px;
    border-right-style: solid;
    color: black !important;
    vertical-align: middle !important;
    font-size: 15px;
    font-weight: 700;
}


.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background: #bbffbb62 !important;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    text-align: left;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    vertical-align: middle;
    border-right: #cdcdce;
    border-right-width: 1px;
    border-right-style: solid;
    border-top: 0;
    border-top-width: 0px;
    border-top-style: initial;
    border-top-color: initial;
    color: rgba(0, 0, 0, 0.87);
    transition: width 0.3s ease;
    font-size: 14px;
    font-weight: 400;
}

.note-group-select-from-files {
    display: none;
}

//Settings Menu

.settings-menu {
    margin: 0 -12px;
    color: #333;
}

.settings-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.settings-menu ul li {
    float: left;
    width: 25%;
    padding: 12px;
    color: #333;
    font-weight: 500;
    font-size: 16px;
}

.settings-menu ul li a {
    color: #333;
}

.settings-menu ul li:nth-child(4n+1) {
    clear: left;
}

.settings-menu ul ul {
    margin: 10px 0 0 15px;
    border-left: solid 1px #c7c7c7;
}

.settings-menu ul ul li {
    float: none;
    width: auto;
    padding: 5px 0;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    padding-left: 22px;
}

.settings-menu ul ul li:before {
    content: '';
    width: 10px;
    height: 1px;
    background: #c7c7c7;
    position: absolute;
    left: 0;
    top: 15px;
}

@media (max-width:1199px) {
    .settings-menu ul li {
        width: 33.333%;
    }

    .settings-menu ul li:nth-child(4n+1) {
        clear: none;
    }

    .settings-menu ul li:nth-child(3n+1) {
        clear: left;
    }
}

@media (max-width:767px) {
    .settings-menu ul li {
        width: 50%;
    }

    .settings-menu ul li:nth-child(3n+1) {
        clear: none;
    }

    .settings-menu ul li:nth-child(2n+1) {
        clear: left;
    }
}

@media (max-width:479px) {
    .settings-menu ul li {
        width: 100%;
    }
}


.templatesSection {
    height: 800px;
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-top: 0px !important;
    overflow-x: hidden;
    border: 1px solid rgb(204, 203, 203);
    background-repeat: repeat;
}


// chat
.noPadding {
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.panelChat1 {
    border-radius: 0px;
    background-color: #efefef;
}

.panelChat2 {
    border-radius: 0px;
    background-color: #efefef;
}

.chatSection {
    height: 390px;
    padding: 5px !important;
    background-color: #F8F8F8;
    overflow-x: hidden;
    border: 1px solid rgb(204, 203, 203);
    background-repeat: repeat;
}

.chatSectionLg {
    height: 710px;
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-top: 0px !important;
    background-color: #F8F8F8;
    overflow-x: hidden;
    border: 1px solid rgb(204, 203, 203);
    background-repeat: repeat;
}

.discussion {
    list-style: none;
    border: solid 1px #e5e5e5;
    margin: 0;
    padding: 25px 0;
}

.discussion li {
    padding: 0.5rem;
    overflow: visible;
    display: flex;
    margin-right: 20px;
    margin-left: 20px;
}

.self {
    justify-content: flex-end;
    align-items: flex-end;
}

.other {
    justify-content: flex-start;
    align-items: flex-start;
}

.messages {
    background: white;
    padding: 12px;
    border-radius: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    position: relative;
}

.messages p {
    font-size: 12px;
    margin: 0 0 0.2rem 0;
    color: #FFF;
}

.messages time {
    font-size: 10px;
    color: #b9b9b9;
    float: right;
    font-weight: 500;
}

//Global
.form-group {
    margin-bottom: 0.25rem !important;
}

.col-form-label-static {
    padding: 2px 2px 2px 2px !important;
}

.form-control-static {
    padding: 2px 2px 2px 2px !important;
}

.custom-checkbox {
    padding-top: 5px !important;
}

.myControlSmallLabel {
    font-size: 0.765625rem;
    border-color: transparent;
    background-color: transparent;
}

textarea {
    resize: none !important;
}

.myAccordion .card-header {
    padding: 0px !important;
}

.myAccordion .btn-link {
    font: normal !important;
}


.vl {
    border-right: thin solid rgba(211, 211, 211, 0.767);
}


.cdk-overlay-container {
    z-index: 99999 !important;
}

.cdk-global-scrollblock {
    position: static !important;
    width: initial !important;
    overflow-y: inherit !important;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.btn-success {
    background-color: #749F74 !important;
    border-color: #659265 !important;
}

.bg-success {
    background-color: #749F74 !important;
    border-color: #659265 !important;
}

.btn-danger {
    background-color: #a90329 !important;
    border-color: #900323 !important;
}

.btn-primary {
    background-color: $nx-info !important;
    border-color: $nx-info  !important;
}


.modal-success .modal-content .modal-header {
    color: $nx-white;
    background-color: $nx-green-color !important;
}

.modal-success .modal-content {
    border-color: $nx-green-color !important;
}

.modal-primary .modal-content .modal-header {
    color: $nx-white;
    background-color: $nx-blue-color !important;

}

.modal-primary .modal-content {
    border-color: #2e6da4 !important;
}


.form-control:disabled,
.form-control[readonly] {
    background-color: whitesmoke;
    opacity: 1;
}

/////-----------------------------------------------------------------------------
// stripo
#externalSystemContainer {
    background-color: darkgrey;
    padding: 5px 0 5px 20px;
}

#stripoSettingsContainer {
    width: 400px;
    float: left;
}

#stripoPreviewContainer {
    width: calc(100% - 400px);
    float: left;
}

.notification-zone {
    position: fixed;
    width: 400px;
    z-index: 99999;
    right: 20px;
    bottom: 80px;
}

.control-button {
    border-radius: 17px;
    padding: 5px 10px;
    border-color: grey;
}


#stripoSettingsContainer>div>ul>li {
    width: 180px !important;
}


/////-----------------------------------------------------------------------------
// campaign list
.ngx-datatable.material.no-campaigns-created .empty-row {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 25px;
}

mat-sidenav-container.mat-drawer-container.mat-sidenav-container.main-panel {
    z-index: 1041 !important;
}

.nav-link.active {
    color: unset !important;
}



.badge-primary {
    color: $nx-white;
    background-color: $nx-info !important;
}

.fa.fa-arrow-up,
.fa.fa-arrow-down {
    color: $nx-info !important;
}

.btn-outline-primary:hover {
    color: $nx-white !important;
    background-color: $nx-info !important;
    border-color: $nx-info !important;
}

// 
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: $nx-black;
    background-color: $nx-white;
    border-color: $nx-custom-secondary-gray-color $nx-custom-secondary-gray-color $nx-white;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: $nx-grey;
}

.tab-content .tab-pane {
    padding: 1rem;
}

.card-block .tab-content {
    margin-top: 0;
    border: 0
}

.tab-content {
    margin-top: -1px;
    background: $nx-white;
    border: 1px solid $nx-custom-secondary-gray-color;
    border-radius: 0 0 .25rem .25rem;
}

// 


.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
    color: $nx-black;
    background-color: $nx-custom-secondary-gray-color;
    border-color: $nx-custom-secondary-gray-color;
}

.btn-secondary:hover,
.btn-secondary:active {
    color: $nx-black !important;
    background-color: $nx-custom-secondary-gray-color !important;
    border-color: $nx-custom-secondary-gray-color !important;
}

//**** Start - Change Bootstrap Color ****

.text-primary,
.btn.btn-link {
    color: $nx-info !important;
}

button.btn.btn-success.btn-sm {
    z-index: unset !important;
}

.mat-drawer.mat-drawer-side {
    z-index: 1 !important;
}

.modal {
    background-color: $nx-black-color-opacity
}

.d-flex.flex-wrap {
    gap: 4px;
}

.badge {
    padding: 4px 8px !important;
}

.btn-outline-primary {
    color: $nx-info !important;
}

//**** End - Change Bootstrap Color ****