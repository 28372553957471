// sprint kit
$spinkit-spinner-color: blue;
@import 'spinkit/scss/spinkit.scss';
// Components:
// ngx datatable
@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import 'node_modules/@swimlane/ngx-datatable/themes/dark.scss';
@import 'node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';
// ng select
@import "@ng-select/ng-select/themes/default.theme.css";
// summer note
@import "summernote/dist/summernote-lite.min.css";
// date picker
@import "ng-pick-datetime/assets/style/picker.min.css";
// ngx
@import "ngx-bootstrap/datepicker/bs-datepicker.css";
@import 'ngx-toastr/toastr';
// icons
@import "flag-icon-css/css/flag-icon.css";
@import "font-awesome/css/font-awesome.css";
@import "simple-line-icons/css/simple-line-icons.css";