@use 'sass:map';
@use '../nextech-palettes/bw-common-palettes.scss' as *;

button.nx-black {
    background-color: $nx-black;
    color: $nx-white;
}

button.nx-dark-grey {
    background-color: $nx-dark-grey;
    color: $nx-white;
}

button.nx-grey {
    background-color: $nx-grey;
    color: $nx-white;
}

button.nx-light-grey {
    background-color: $nx-light-grey;
    color: $nx-black;
}

button.nx-washed-grey {
    background-color: $nx-washed-grey;
    color: $nx-black;
}

button.nx-white {
    background-color: $nx-white;
    color: $nx-black;
}