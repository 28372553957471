@use 'sass:map';
@use '@angular/material' as mat;
@use './nlm-colors.scss' as *;
@use './nextech-palettes/bw-common-palettes.scss' as *;

// #region button-styles-dark

// #region nx-dark-primary

button.nx-dark-primary {
    background-color: $colors-nx-dark-primary-500 !important;
    color: $colors-nx-dark-primary-900-contrast !important;
}

button.nx-dark-primary-100 {
    background-color: $colors-nx-dark-primary-100 !important;
    color: $colors-nx-dark-primary-100-contrast !important;
}

button.nx-dark-primary-300 {
    background-color: $colors-nx-dark-primary-300 !important;
    color: $colors-nx-dark-primary-300-contrast !important;
}

button.nx-dark-primary-500 {
    background-color: $colors-nx-dark-primary-500 !important;
    color: $colors-nx-dark-primary-500-contrast !important;
}

button.nx-dark-primary-700 {
    background-color: $colors-nx-dark-primary-700 !important;
    color: $colors-nx-dark-primary-700-contrast !important;
}

button.nx-dark-primary-900 {
    background-color: $colors-nx-dark-primary-900 !important;
    color: $colors-nx-dark-primary-900-contrast !important;
}

// #endregion

// #region dark-accent

button.nx-dark-accent {
    background-color: $colors-nx-dark-secondary-500 !important;
    color: $colors-nx-dark-secondary-900-contrast !important;
}

button.nx-dark-accent-100 {
    background-color: $colors-nx-dark-secondary-100 !important;
    color: $colors-nx-dark-secondary-100-contrast !important;
}

button.nx-dark-accent-300 {
    background-color: $colors-nx-dark-secondary-300 !important;
    color: $colors-nx-dark-secondary-300-contrast !important;
}

button.nx-dark-accent-500 {
    background-color: $colors-nx-dark-secondary-500 !important;
    color: $colors-nx-dark-secondary-500-contrast !important;
}

button.nx-dark-accent-700 {
    background-color: $colors-nx-dark-secondary-700 !important;
    color: $colors-nx-dark-secondary-700-contrast !important;
}

button.nx-dark-accent-900 {
    background-color: $colors-nx-dark-secondary-900 !important;
    color: $colors-nx-dark-secondary-900-contrast !important;
}

// #endregion

// #region dark-warn

button.nx-dark-warn {
    background-color: $colors-nx-dark-warn-500 !important;
    color: $colors-nx-dark-warn-900-contrast !important;
}

button.nx-dark-warn-100 {
    background-color: $colors-nx-dark-warn-100 !important;
    color: $colors-nx-dark-warn-100-contrast !important;
}

button.nx-dark-warn-300 {
    background-color: $colors-nx-dark-warn-300 !important;
    color: $colors-nx-dark-warn-300-contrast !important;
}

button.nx-dark-warn-500 {
    background-color: $colors-nx-dark-warn-500 !important;
    color: $colors-nx-dark-warn-500-contrast !important;
}

button.nx-dark-warn-700 {
    background-color: $colors-nx-dark-warn-700 !important;
    color: $colors-nx-dark-warn-700-contrast !important;
}

button.nx-dark-warn-900 {
    background-color: $colors-nx-dark-warn-900 !important;
    color: $colors-nx-dark-warn-900-contrast !important;
}

// #endregion

// #region dark-success-scheme

button.nx-dark-success {
    background-color: $colors-nx-dark-success-900 !important;
    color: $colors-nx-dark-success-900-contrast !important;
}

button.nx-dark-success-100 {
    background-color: $colors-nx-dark-success-100 !important;
    color: $colors-nx-dark-success-100-contrast !important;
}

button.nx-dark-success-300 {
    background-color: $colors-nx-dark-success-300 !important;
    color: $colors-nx-dark-success-300-contrast !important;
}

button.nx-dark-success-500 {
    background-color: $colors-nx-dark-success-500 !important;
    color: $colors-nx-dark-success-500-contrast !important;
}

button.nx-dark-success-700 {
    background-color: $colors-nx-dark-success-700 !important;
    color: $colors-nx-dark-success-700-contrast !important;
}

button.nx-dark-success-900 {
    background-color: $colors-nx-dark-success-900 !important;
    color: $colors-nx-dark-success-900-contrast !important;
}

// #endregion

// #region dark-caution

button.nx-dark-caution {
    background-color: $colors-nx-dark-caution-900 !important;
    color: $colors-nx-dark-caution-500-contrast !important;
}

button.nx-dark-caution-100 {
    background-color: $colors-nx-dark-caution-100 !important;
    color: $colors-nx-dark-caution-100-contrast !important;
}

button.nx-dark-caution-300 {
    background-color: $colors-nx-dark-caution-300 !important;
    color: $colors-nx-dark-caution-300-contrast !important;
}

button.nx-dark-caution-500 {
    background-color: $colors-nx-dark-caution-500 !important;
    color: $colors-nx-dark-caution-500-contrast !important;
}

button.nx-dark-caution-700 {
    background-color: $colors-nx-dark-caution-700 !important;
    color: $colors-nx-dark-caution-700-contrast !important;
}

button.nx-dark-caution-900 {
    background-color: $colors-nx-dark-caution-900 !important;
    color: $colors-nx-dark-caution-900-contrast !important;
}

// #endregion

// #region dark-info

button.nx-dark-info {
    background-color: $colors-nx-dark-info-500 !important;
    color: $colors-nx-dark-info-500-contrast !important;
}

button.nx-dark-info-100 {
    background-color: $colors-nx-dark-info-100 !important;
    color: $colors-nx-dark-info-100-contrast !important;
}

button.nx-dark-info-300 {
    background-color: $colors-nx-dark-info-300 !important;
    color: $colors-nx-dark-info-300-contrast !important;
}

button.nx-dark-info-500 {
    background-color: $colors-nx-dark-info-500 !important;
    color: $colors-nx-dark-info-500-contrast !important;
}

button.nx-dark-info-700 {
    background-color: $colors-nx-dark-info-700 !important;
    color: $colors-nx-dark-info-700-contrast !important;
}

button.nx-dark-info-900 {
    background-color: $colors-nx-dark-info-900 !important;
    color: $colors-nx-dark-info-900-contrast !important;
}

// #endregion

// #endregion

// #region button-styles-light

// #region light nx-primary

button.nx-primary {
    background-color: $colors-nx-light-primary-600 !important;
    color: $colors-nx-light-primary-600-contrast !important;
}

button.nx-primary-100 {
    background-color: $colors-nx-light-primary-100 !important;
    color: $colors-nx-light-primary-100-contrast !important;
}

button.nx-primary-300 {
    background-color: $colors-nx-light-primary-300 !important;
    color: $colors-nx-light-primary-300-contrast !important;
}

button.nx-primary-500 {
    background-color: $colors-nx-light-primary-500 !important;
    color: $colors-nx-light-primary-500-contrast !important;
}

button.nx-primary-700 {
    background-color: $colors-nx-light-primary-700 !important;
    color: $colors-nx-light-primary-700-contrast !important;
}

button.nx-primary-900 {
    background-color: $colors-nx-light-primary-900 !important;
    color: $colors-nx-light-primary-900-contrast !important;
}

// #endregion

// #region light nx-accent

button.nx-accent {
    background-color: $colors-nx-light-secondary-500 !important;
    color: $colors-nx-light-secondary-600-contrast !important;
}

button.nx-accent-100 {
    background-color: $colors-nx-light-secondary-100 !important;
    color: $colors-nx-light-secondary-100-contrast !important;
}

button.nx-accent-300 {
    background-color: $colors-nx-light-secondary-300 !important;
    color: $colors-nx-light-secondary-300-contrast !important;
}

button.nx-accent-500 {
    background-color: $colors-nx-light-secondary-500 !important;
    color: $colors-nx-light-secondary-500-contrast !important;
}

button.nx-accent-700 {
    background-color: $colors-nx-light-secondary-700 !important;
    color: $colors-nx-light-secondary-700-contrast !important;
}

button.nx-accent-900 {
    background-color: $colors-nx-light-secondary-700 !important;
    color: $colors-nx-light-secondary-700-contrast !important;
}

// #endregion

// #region light nx-warn

button.nx-warn {
    background-color: $colors-nx-light-warn-500 !important;
    color: $colors-nx-light-warn-500-contrast !important;
}

button.nx-warn-100 {
    background-color: $colors-nx-light-warn-100 !important;
    color: $colors-nx-light-warn-100-contrast !important;
}

button.nx-warn-300 {
    background-color: $colors-nx-light-warn-300 !important;
    color: $colors-nx-light-warn-300-contrast !important;
}

button.nx-warn-500 {
    background-color: $colors-nx-light-warn-500 !important;
    color: $colors-nx-light-warn-500-contrast !important;
}

button.nx-warn-700 {
    background-color: $colors-nx-light-warn-700 !important;
    color: $colors-nx-light-warn-700-contrast !important;
}

button.nx-warn-900 {
    background-color: $colors-nx-light-warn-900 !important;
    color: $colors-nx-light-warn-900-contrast !important;
}

// #endregion

// #region light nx-success

button.nx-success {
    background-color: $colors-nx-light-success-500 !important;
    color: $colors-nx-light-success-500-contrast !important;
}

button.nx-success-100 {
    background-color: $colors-nx-light-success-100 !important;
    color: $colors-nx-light-success-100-contrast !important;
}

button.nx-success-300 {
    background-color: $colors-nx-light-success-300 !important;
    color: $colors-nx-light-success-300-contrast !important;
}

button.nx-success-500 {
    background-color: $colors-nx-light-success-500 !important;
    color: $colors-nx-light-success-500-contrast !important;
}

button.nx-success-700 {
    background-color: $colors-nx-light-success-700 !important;
    color: $colors-nx-light-success-700-contrast !important;
}

button.nx-success-900 {
    background-color: $colors-nx-light-success-900 !important;
    color: $colors-nx-light-success-900-contrast !important;
}

// #endregion

// #region light nx-caution

button.nx-caution {
    background-color: $colors-nx-light-caution-500 !important;
    color: $colors-nx-light-caution-500-contrast !important;
}

button.nx-caution-100 {
    background-color: $colors-nx-light-caution-100 !important;
    color: $colors-nx-light-caution-100-contrast !important;
}

button.nx-caution-300 {
    background-color: $colors-nx-light-caution-300 !important;
    color: $colors-nx-light-caution-300-contrast !important;
}

button.nx-caution-500 {
    background-color: $colors-nx-light-caution-500 !important;
    color: $colors-nx-light-caution-500-contrast !important;
}

button.nx-caution-700 {
    background-color: $colors-nx-light-caution-700 !important;
    color: $colors-nx-light-caution-700-contrast !important;
}

button.nx-caution-900 {
    background-color: $colors-nx-light-caution-500 !important;
    color: $colors-nx-light-caution-500-contrast !important;
}

// #endregion

// #region light nx-info

button.nx-info {
    background-color: $colors-nx-light-info-500 !important;
    color: $colors-nx-light-info-500-contrast !important;
}

button.nx-info-100 {
    background-color: $colors-nx-light-info-100 !important;
    color: $colors-nx-light-info-100-contrast !important;
}

button.nx-info-300 {
    background-color: $colors-nx-light-info-300 !important;
    color: $colors-nx-light-info-300-contrast !important;
}

button.nx-info-500 {
    background-color: $colors-nx-light-info-500 !important;
    color: $colors-nx-light-info-500-contrast !important;
}

button.nx-info-700 {
    background-color: $colors-nx-light-info-700 !important;
    color: $colors-nx-light-info-700-contrast !important;
}

button.nx-info-900 {
    background-color: $colors-nx-light-info-900 !important;
    color: $colors-nx-light-info-900-contrast !important;
}

// #endregion

// #region light nx-tertiary

button.nx-tertiary {
    background-color: $colors-nx-light-primary-50 !important;
    color: $colors-nx-light-primary-900 !important;
}

button.nx-tertiary:disabled {
    color: $colors-nx-grey !important;
    background-color: $colors-nx-light-grey !important;
}

// #endregion

// #region light styles

button.nx-btn {
    // width: 125px;
    border-radius: 8px;
    font-family: Helvetica, sans-serif;
}

button.nx-cancel-btn {
    border: 2px solid $colors-nx-light-primary-600 !important;
    background-color: white;
    color: $colors-nx-light-primary-600;
}

// #endregion

// #endregion

// #region button-styles-greys
button.nx-black {
    background-color: $colors-nx-black;
    color: $colors-nx-white;
}

button.nx-dark-grey {
    background-color: $colors-nx-dark-grey;
    color: $colors-nx-white;
}

button.nx-grey {
    background-color: $colors-nx-grey;
    color: $colors-nx-white;
}

button.nx-light-grey {
    background-color: $colors-nx-light-grey;
    color: $colors-nx-black;
}

button.nx-washed-grey {
    background-color: $colors-nx-washed-grey;
    color: $colors-nx-black;
}

button.nx-white {
    background-color: $colors-nx-white;
    color: $colors-nx-black;
}

// #endregion

// #region basic screen
.nlm_base_screen {
    // default screen height  if empty
    min-height: 30vh;

    .mat-card-title {
        font-weight: 700;
    }

    // save and cancel buttons
    .mat-flat-button .mat-stroked-button {
        width: max-content;
    }

    // inputs
    .mat-form-field {
        min-width: 300px;
        line-height: 17px;
    }

    // base screen
    .mat-card-content {
        min-height: 20vh;
        overflow: auto;
    }

    .spinner {
        min-height: 25vh;
    }

    .mat-card-footer {
        padding-top: 10px;
    }
}

// #endregion

// #region master detail screen
.nlm_masterdetail_screen {
    // default screen height  if empty
    min-height: 40vh;


    .mat-card-title {
        font-weight: 700;
    }

    // save and cancel buttons
    .mat-flat-button .mat-stroked-button {
        width: max-content;
    }

    // buttons
    button {
        font-weight: 700;
    }

    // filter input
    .mat-form-field {
        max-width: 450px;
        line-height: 17px;
    }

    // base screen
    .mat-card-content {
        min-height: 20vh;
        padding-bottom: 20px;
        overflow: auto;
    }

    .spinner {
        min-height: 25vh;
    }

    // table
    table {
        width: 100%;
    }

    td th {
        width: 25%;
    }

    .mat-header-cell {
        font-weight: 900;
        color: black;
    }

    .linkTextStyle {
        color: $nx-info;
    }

    .actions {
        width: 130px;
        text-align: center
    }

}

// #endregion

// #region master detail screen dialog
.nlm_masterdetail_dialog {

    // buttons
    button {
        font-weight: 700;
    }

    .mat-dialog-title {
        font-weight: 700;
    }


    // filter input
    .mat-form-field {
        line-height: 17px;
    }

}

// #endregion

// #region basic report
.nlm_base_report {
    // default screen height  if empty
    min-height: 40vh;

    .mat-card-title {
        font-weight: 700;
    }

    // save and cancel buttons
    .mat-flat-button .mat-stroked-button {
        width: max-content;
    }

    // filter input
    .mat-form-field {
        max-width: 420px;
        line-height: 17px;
    }

    // base screen
    .mat-card-content {
        min-height: 20vh;
        padding-bottom: 20px;
        overflow: auto;
    }

    .spinner {
        min-height: 25vh;
    }

    // table
    table {
        width: 100%;
    }

    td th {
        width: 25%;
    }

    .mat-header-cell {
        font-weight: 900;
        color: black;
    }

    .linkTextStyle {
        color: $nx-info;
    }

    .actions {
        width: 130px;
        text-align: center
    }
}

// #endregion

// #region nlm container 
.nlm_container {
    button {
        font-weight: 700;
    }
    .mat-form-field {
        line-height: 17px;
    }
}

// #endregion


.font-normal {
    font-size: 13px;
}

.font-tile {
    font-size: 21px;
}

//**** Start - Business Info & Locations ******
.business-loation {
    .mat-form-field {
        min-width: unset !important;
    }
}
//**** End - Business Info & Locations ******


//**** Start - Notification ******

.center {
    text-align: center !important;
}
//**** End -  Notification ******


//****************Paginator ************************
.mat-form-field-flex, .mat-mdc-form-field-flex {
    background-color: unset !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex, .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
    padding: 0 0 1px 0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix, .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-infix {
    padding: 0 0 2px 0 !important;
}

.mat-select-arrow-wrapper, .mat-mdc-select-arrow-wrapper {
    height: unset !important;
}


//****************Paginator End ************************

//****************Calendar Start ************************

.custom-datepicker {

    //mat hint
    .mat-form-field-subscript-wrapper {
        padding: 0 0 0 1px !important;
        margin-top: 1px !important;
    }

    .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
        width: auto;
        height: 48px;
        padding-top: 4px;
    }

    .mat-date-range-input-container {
        padding-top: 5px;
    }
}
//****************Calendar Start ************************

//****************Material Search Box Start ************************
.mat-form-search-box1 {
    .mat-form-field-infix {
        padding: 11px 0;
    }

    .mat-input-element {
        position: relative;
        top: -3px !important;
    }

    .mat-form-field-subscript-wrapper {
        margin-top: 0 !important;
        padding-left: 0 !important;
    }
}

.mat-form-search-box1:not(.mat-focused) label {
    margin-top: -8px !important;
}


//****************Material Search Box End ************************


//****************Stripo Start ************************

.esdev-app .esdev-option-panel{
    z-index: 0 !important;
}

.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
    background-color:$nx-info !important
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary{
    color:$nx-info !important;
    background-color: $nx-white !important;
}
//****************Stripo End ************************
