@use 'sass:map';
@use '@angular/material' as mat;
@use '../nextech-palettes/bw-common-palettes.scss' as *;
@use '../nextech-palettes/bw-light-palettes.scss' as *;
@use '../nextech-palettes/bw-light-custom-palettes.scss' as *;

// Main light palettes
$nx-light-primary-palette: mat.define-palette($nx-light-primary-scheme, 600, 50, 900);
$nx-light-accent-palette: mat.define-palette($nx-light-accent-scheme, 500, 50, 900);
$nx-light-warn-palette: mat.define-palette(mat.$red-palette);

// Custom light palettes
$nx-light-success-palette: mat.define-palette($nx-light-success-scheme, 500, 100, 900);
$nx-light-caution-palette: mat.define-palette($nx-light-caution-scheme, 500, 100, 900);
$nx-light-info-palette: mat.define-palette($nx-light-info-scheme, 500, 100, 900);

// Light theme classes ---------------------------------------------------------------------------
button.nx-primary {
    background-color: mat.get-color-from-palette($nx-light-primary-palette);
    color: mat.get-color-from-palette($nx-light-primary-palette, '600-contrast');
}

button.nx-primary-100 {
    background-color: mat.get-color-from-palette($nx-light-primary-palette, 100);
    color: mat.get-color-from-palette($nx-light-primary-palette, '100-contrast');
}

button.nx-primary-300 {
    background-color: mat.get-color-from-palette($nx-light-primary-palette, 300);
    color: mat.get-color-from-palette($nx-light-primary-palette, '300-contrast');
}

button.nx-primary-500 {
    background-color: mat.get-color-from-palette($nx-light-primary-palette, 500);
    color: mat.get-color-from-palette($nx-light-primary-palette, '500-contrast');
}

button.nx-primary-700 {
    background-color: mat.get-color-from-palette($nx-light-primary-palette, 700);
    color: mat.get-color-from-palette($nx-light-primary-palette, '700-contrast');
}

button.nx-primary-900 {
    background-color: mat.get-color-from-palette($nx-light-primary-palette, 900);
    color: mat.get-color-from-palette($nx-light-primary-palette, '900-contrast');
}

button.nx-accent {
    background-color: mat.get-color-from-palette($nx-light-accent-palette);
    color: mat.get-color-from-palette($nx-light-accent-palette, '600-contrast');
}

button.nx-accent-100 {
    background-color: mat.get-color-from-palette($nx-light-accent-palette, 100);
    color: mat.get-color-from-palette($nx-light-accent-palette, '100-contrast');
}

button.nx-accent-300 {
    background-color: mat.get-color-from-palette($nx-light-accent-palette, 300);
    color: mat.get-color-from-palette($nx-light-accent-palette, '300-contrast');
}

button.nx-accent-500 {
    background-color: mat.get-color-from-palette($nx-light-accent-palette, 500);
    color: mat.get-color-from-palette($nx-light-accent-palette, '500-contrast');
}

button.nx-accent-700 {
    background-color: mat.get-color-from-palette($nx-light-accent-palette, 700);
    color: mat.get-color-from-palette($nx-light-accent-palette, '700-contrast');
}

button.nx-accent-900 {
    background-color: mat.get-color-from-palette($nx-light-accent-palette, 900);
    color: mat.get-color-from-palette($nx-light-accent-palette, '900-contrast');
}

button.nx-warn {
    background-color: mat.get-color-from-palette($nx-light-warn-palette);
    color: mat.get-color-from-palette($nx-light-warn-palette, '500-contrast');
}

button.nx-warn-100 {
    background-color: mat.get-color-from-palette($nx-light-warn-palette, 100);
    color: mat.get-color-from-palette($nx-light-warn-palette, '100-contrast');
}

button.nx-warn-300 {
    background-color: mat.get-color-from-palette($nx-light-warn-palette, 300);
    color: mat.get-color-from-palette($nx-light-warn-palette, '300-contrast');
}

button.nx-warn-500 {
    background-color: mat.get-color-from-palette($nx-light-warn-palette, 500);
    color: mat.get-color-from-palette($nx-light-warn-palette, '500-contrast');
}

button.nx-warn-700 {
    background-color: mat.get-color-from-palette($nx-light-warn-palette, 700);
    color: mat.get-color-from-palette($nx-light-warn-palette, '700-contrast');
}

button.nx-warn-900 {
    background-color: mat.get-color-from-palette($nx-light-warn-palette, 900);
    color: mat.get-color-from-palette($nx-light-warn-palette, '900-contrast');
}

// Custom
button.nx-success {
    background-color: mat.get-color-from-palette($nx-light-success-palette);
    color: mat.get-color-from-palette($nx-light-success-palette, '500-contrast');
}

button.nx-success-100 {
    background-color: mat.get-color-from-palette($nx-light-success-palette, 100);
    color: mat.get-color-from-palette($nx-light-success-palette, '100-contrast');
}

button.nx-success-300 {
    background-color: mat.get-color-from-palette($nx-light-success-palette, 300);
    color: mat.get-color-from-palette($nx-light-success-palette, '300-contrast');
}

button.nx-success-500 {
    background-color: mat.get-color-from-palette($nx-light-success-palette, 500);
    color: mat.get-color-from-palette($nx-light-success-palette, '500-contrast');
}

button.nx-success-700 {
    background-color: mat.get-color-from-palette($nx-light-success-palette, 700);
    color: mat.get-color-from-palette($nx-light-success-palette, '700-contrast');
}

button.nx-success-900 {
    background-color: mat.get-color-from-palette($nx-light-success-palette, 900);
    color: mat.get-color-from-palette($nx-light-success-palette, '900-contrast');
}

button.nx-caution {
    background-color: mat.get-color-from-palette($nx-light-caution-palette);
    color: mat.get-color-from-palette($nx-light-caution-palette, '500-contrast');
}

button.nx-caution-100 {
    background-color: mat.get-color-from-palette($nx-light-caution-palette, 100);
    color: mat.get-color-from-palette($nx-light-caution-palette, '100-contrast');
}

button.nx-caution-300 {
    background-color: mat.get-color-from-palette($nx-light-caution-palette, 300);
    color: mat.get-color-from-palette($nx-light-caution-palette, '300-contrast');
}

button.nx-caution-500 {
    background-color: mat.get-color-from-palette($nx-light-caution-palette, 500);
    color: mat.get-color-from-palette($nx-light-caution-palette, '500-contrast');
}

button.nx-caution-700 {
    background-color: mat.get-color-from-palette($nx-light-caution-palette, 700);
    color: mat.get-color-from-palette($nx-light-caution-palette, '700-contrast');
}

button.nx-caution-900 {
    background-color: mat.get-color-from-palette($nx-light-caution-palette, 900);
    color: mat.get-color-from-palette($nx-light-caution-palette, '900-contrast');
}

button.nx-info {
    background-color: mat.get-color-from-palette($nx-light-info-palette);
    color: mat.get-color-from-palette($nx-light-info-palette, '500-contrast');
}

button.nx-info-100 {
    background-color: mat.get-color-from-palette($nx-light-info-palette, 100);
    color: mat.get-color-from-palette($nx-light-info-palette, '100-contrast');
}

button.nx-info-300 {
    background-color: mat.get-color-from-palette($nx-light-info-palette, 300);
    color: mat.get-color-from-palette($nx-light-info-palette, '300-contrast');
}

button.nx-info-500 {
    background-color: mat.get-color-from-palette($nx-light-info-palette, 500);
    color: mat.get-color-from-palette($nx-light-info-palette, '500-contrast');
}

button.nx-info-700 {
    background-color: mat.get-color-from-palette($nx-light-info-palette, 700);
    color: mat.get-color-from-palette($nx-light-info-palette, '700-contrast');
}

button.nx-info-900 {
    background-color: mat.get-color-from-palette($nx-light-info-palette, 900);
    color: mat.get-color-from-palette($nx-light-info-palette, '900-contrast');
}

button.nx-btn {
    width: 125px;
    border-radius: 8px;
    font-family: Helvetica, sans-serif;
}

button.nx-cancel-btn {
    border: 2px solid mat.get-color-from-palette($nx-light-primary-palette) !important;
    background-color: white;
    color: mat.get-color-from-palette($nx-light-primary-palette);
}

// old colours uncomment to use
// button.cancel-btn {
//     border: 2px solid #66bae5 !important;
//     background-color: white;
//     color: #66bae5;
// }
