// @use './index' as *;
@use 'sass:map';
@use '@angular/material' as mat;
@use './nextech-palettes/bw-common-palettes.scss' as *;
@use './nextech-palettes/bw-light-palettes.scss' as *;
@use './nextech-palettes/bw-light-custom-palettes.scss' as *;
@use './nextech-palettes/bw-dark-palettes.scss' as *;
@use './nextech-palettes/bw-dark-custom-palettes.scss' as *;

// Specifies the font we will use on your components
$custom-typography: mat.define-legacy-typography-config($font-family: 'Open Sans, sans-serif');

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

// Light theme main palettes
/*
Note: Parameters for the mat-palette function => mat-palette(fullpalette, defaultColor, lighterColor, darkerColor, textColor)
with the exception of the full palette all other parameters are optional.
*/
$nx-light-primary-palette: mat.define-palette($nx-light-primary-scheme, 600, 50, 900);
$nx-light-accent-palette: mat.define-palette($nx-light-accent-scheme, 500, 50, 900);
$nx-light-warn-palette: mat.define-palette(mat.$red-palette);

// Create light theme
/*
Note: mat.define-light-theme or mat.define-dark-theme recives 2 parameters "color" and "typography"

Code example:
$kids-theme: mat.define-light-theme((
   color: (
     primary: $my-primary,
     accent: $my-accent,
     warn: $my-warn
   ),
   typography: $kids-typography,
  ));
*/
$nx-light-theme: mat.define-light-theme((
  color: (
    primary: $nx-light-primary-palette,
    accent: $nx-light-accent-palette,
    warn: $nx-light-warn-palette,
  )
));

/*
Note: This will use this theme for all 35+ components avilable in Angular materials
we can also specify individual components and only overwrite those we are usnig in the app
this can save performance by avoiding generating unnecessary CSS files.

Note: If we want to overwrite individual components we need to add and aditiona mixin
@include mat.core-theme($my-theme); this is for common features used by multiple components.

Example:
@include mat.core-theme($my-theme);
@include mat.button-theme($my-theme);
@include mat.table-theme($my-theme);
...
add other mat components here...
*/
@include mat.all-legacy-component-themes($nx-light-theme);

// Dark theme main palettes
$nx-dark-primary-palette: mat.define-palette($nx-dark-primary-scheme, 500, 50, 900);
$nx-dark-accent-palette: mat.define-palette($nx-dark-accent-scheme, 500, 50, 900);
$nx-dark-warn-palette: mat.define-palette(mat.$red-palette);

// Create dark theme
$nx-dark-theme: mat.define-dark-theme((
  color: (
    primary: $nx-dark-primary-palette,
    accent: $nx-dark-accent-palette,
    warn: $nx-dark-warn-palette,
  )
));

.darkMode {
  @include mat.all-legacy-component-colors($nx-dark-theme);
}

.custom-dialog-container .mat-dialog-container {
  padding-top: 12px;
  border-radius: 8px;
}

.mat-form-field-without-padding .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: $nx-info !important;
}

/* Change the border hover color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: $nx-info !important;
}

/* Change label hover color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing label {
  color: $nx-info;
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick{ 
  color: $nx-info !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336 !important;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch{
  width: calc(123px * var(--mat-mdc-form-field-floating-label-scale, 0.75) + 9px) !important;
}

mat-dialog-container#account-email-template{
  height: 95vh;
}

@media only screen and (max-width: 1024px) {
  mat-dialog-container#account-email-template{
    height: auto;
  }
}

@media only screen and (max-width: 598px) {

  /* Change the border color */
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: transparent !important;
  }

  /* Change the border focused color */
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: transparent !important;
  }

  /* Change the border hover color */
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: transparent !important;
  }

  .mat-form-field-infix{
    width: auto !important;
  }

  mat-dialog-container#account-email-template{
    height: auto;
  }
}