@use 'sass:map';
@use '@angular/material' as mat;
@use '../nextech-palettes/bw-common-palettes.scss' as *;
@use '../nextech-palettes/bw-dark-palettes.scss' as *;
@use '../nextech-palettes/bw-dark-custom-palettes.scss' as *;


// Main dark palettes
$nx-dark-primary-palette: mat.define-palette($nx-dark-primary-scheme, 500, 50, 900);
$nx-dark-accent-palette: mat.define-palette($nx-dark-accent-scheme, 500, 50, 900);
$nx-dark-warn-palette: mat.define-palette(mat.$red-palette);

// Custom dark palettes
$nx-dark-success-palette: mat.define-palette($nx-dark-success-scheme, 900, 100, 900);
$nx-dark-caution-palette: mat.define-palette($nx-dark-caution-scheme, 900, 100, 900);
$nx-dark-info-palette: mat.define-palette($nx-dark-info-scheme, 900, 100, 900);

// Dark theme classes ---------------------------------------------------------------------------
button.nx-dark-primary {
    background-color: mat.get-color-from-palette($nx-dark-primary-palette) !important;
    color: mat.get-color-from-palette($nx-dark-primary-palette, '900-contrast') !important;
}

button.nx-dark-primary-100 {
    background-color: mat.get-color-from-palette($nx-dark-primary-palette, 100) !important;
    color: mat.get-color-from-palette($nx-dark-primary-palette, '100-contrast') !important;
}

button.nx-dark-primary-300 {
    background-color: mat.get-color-from-palette($nx-dark-primary-palette, 300) !important;
    color: mat.get-color-from-palette($nx-dark-primary-palette, '300-contrast') !important;
}

button.nx-dark-primary-500 {
    background-color: mat.get-color-from-palette($nx-dark-primary-palette, 500) !important;
    color: mat.get-color-from-palette($nx-dark-primary-palette, '500-contrast') !important;
}

button.nx-dark-primary-700 {
    background-color: mat.get-color-from-palette($nx-dark-primary-palette, 700) !important;
    color: mat.get-color-from-palette($nx-dark-primary-palette, '700-contrast') !important;
}

button.nx-dark-primary-900 {
    background-color: mat.get-color-from-palette($nx-dark-primary-palette, 900) !important;
    color: mat.get-color-from-palette($nx-dark-primary-palette, '900-contrast') !important;
}

button.nx-dark-accent {
    background-color: mat.get-color-from-palette($nx-dark-accent-palette) !important;
    color: mat.get-color-from-palette($nx-dark-accent-palette, '900-contrast') !important;
}

button.nx-dark-accent-100 {
    background-color: mat.get-color-from-palette($nx-dark-accent-palette, 100) !important;
    color: mat.get-color-from-palette($nx-dark-accent-palette, '100-contrast') !important;
}

button.nx-dark-accent-300 {
    background-color: mat.get-color-from-palette($nx-dark-accent-palette, 300) !important;
    color: mat.get-color-from-palette($nx-dark-accent-palette, '300-contrast') !important;
}

button.nx-dark-accent-500 {
    background-color: mat.get-color-from-palette($nx-dark-accent-palette, 500) !important;
    color: mat.get-color-from-palette($nx-dark-accent-palette, '500-contrast') !important;
}

button.nx-dark-accent-700 {
    background-color: mat.get-color-from-palette($nx-dark-accent-palette, 700) !important;
    color: mat.get-color-from-palette($nx-dark-accent-palette, '700-contrast') !important;
}

button.nx-dark-accent-900 {
    background-color: mat.get-color-from-palette($nx-dark-accent-palette, 900) !important;
    color: mat.get-color-from-palette($nx-dark-accent-palette, '900-contrast') !important;
}

button.nx-dark-warn {
    background-color: mat.get-color-from-palette($nx-dark-warn-palette) !important;
    color: mat.get-color-from-palette($nx-dark-warn-palette, '900-contrast') !important;
}

button.nx-dark-warn-100 {
    background-color: mat.get-color-from-palette($nx-dark-warn-palette, 100) !important;
    color: mat.get-color-from-palette($nx-dark-warn-palette, '100-contrast') !important;
}

button.nx-dark-warn-300 {
    background-color: mat.get-color-from-palette($nx-dark-warn-palette, 300) !important;
    color: mat.get-color-from-palette($nx-dark-warn-palette, '300-contrast') !important;
}

button.nx-dark-warn-500 {
    background-color: mat.get-color-from-palette($nx-dark-warn-palette, 500) !important;
    color: mat.get-color-from-palette($nx-dark-warn-palette, '500-contrast') !important;
}

button.nx-dark-warn-700 {
    background-color: mat.get-color-from-palette($nx-dark-warn-palette, 700) !important;
    color: mat.get-color-from-palette($nx-dark-warn-palette, '700-contrast') !important;
}

button.nx-dark-warn-900 {
    background-color: mat.get-color-from-palette($nx-dark-warn-palette, 900) !important;
    color: mat.get-color-from-palette($nx-dark-warn-palette, '900-contrast') !important;
}

// Custom
button.nx-dark-success {
    background-color: mat.get-color-from-palette($nx-dark-success-palette) !important;
    color: mat.get-color-from-palette($nx-dark-success-palette, '900-contrast') !important;
}

button.nx-dark-success-100 {
    background-color: mat.get-color-from-palette($nx-dark-success-palette, 100) !important;
    color: mat.get-color-from-palette($nx-dark-success-palette, '100-contrast') !important;
}

button.nx-dark-success-300 {
    background-color: mat.get-color-from-palette($nx-dark-success-palette, 300) !important;
    color: mat.get-color-from-palette($nx-dark-success-palette, '300-contrast') !important;
}

button.nx-dark-success-500 {
    background-color: mat.get-color-from-palette($nx-dark-success-palette, 500) !important;
    color: mat.get-color-from-palette($nx-dark-success-palette, '500-contrast') !important;
}

button.nx-dark-success-700 {
    background-color: mat.get-color-from-palette($nx-dark-success-palette, 700) !important;
    color: mat.get-color-from-palette($nx-dark-success-palette, '700-contrast') !important;
}

button.nx-dark-success-900 {
    background-color: mat.get-color-from-palette($nx-dark-success-palette, 900) !important;
    color: mat.get-color-from-palette($nx-dark-success-palette, '900-contrast') !important;
}

button.nx-dark-caution {
    background-color: mat.get-color-from-palette($nx-dark-caution-palette) !important;
    color: mat.get-color-from-palette($nx-dark-caution-palette, '500-contrast') !important;
}

button.nx-dark-caution-100 {
    background-color: mat.get-color-from-palette($nx-dark-caution-palette, 100) !important;
    color: mat.get-color-from-palette($nx-dark-caution-palette, '100-contrast') !important;
}

button.nx-dark-caution-300 {
    background-color: mat.get-color-from-palette($nx-dark-caution-palette, 300) !important;
    color: mat.get-color-from-palette($nx-dark-caution-palette, '300-contrast') !important;
}

button.nx-dark-caution-500 {
    background-color: mat.get-color-from-palette($nx-dark-caution-palette, 500) !important;
    color: mat.get-color-from-palette($nx-dark-caution-palette, '500-contrast') !important;
}

button.nx-dark-caution-700 {
    background-color: mat.get-color-from-palette($nx-dark-caution-palette, 700) !important;
    color: mat.get-color-from-palette($nx-dark-caution-palette, '700-contrast') !important;
}

button.nx-dark-caution-900 {
    background-color: mat.get-color-from-palette($nx-dark-caution-palette, 900) !important;
    color: mat.get-color-from-palette($nx-dark-caution-palette, '900-contrast') !important;
}

button.nx-dark-info {
    background-color: mat.get-color-from-palette($nx-dark-info-palette) !important;
    color: mat.get-color-from-palette($nx-dark-info-palette, '500-contrast') !important;
}

button.nx-dark-info-100 {
    background-color: mat.get-color-from-palette($nx-dark-info-palette, 100) !important;
    color: mat.get-color-from-palette($nx-dark-info-palette, '100-contrast') !important;
}

button.nx-dark-info-300 {
    background-color: mat.get-color-from-palette($nx-dark-info-palette, 300) !important;
    color: mat.get-color-from-palette($nx-dark-info-palette, '300-contrast') !important;
}

button.nx-dark-info-500 {
    background-color: mat.get-color-from-palette($nx-dark-info-palette, 500) !important;
    color: mat.get-color-from-palette($nx-dark-info-palette, '500-contrast') !important;
}

button.nx-dark-info-700 {
    background-color: mat.get-color-from-palette($nx-dark-info-palette, 700) !important;
    color: mat.get-color-from-palette($nx-dark-info-palette, '700-contrast') !important;
}

button.nx-dark-info-900 {
    background-color: mat.get-color-from-palette($nx-dark-info-palette, 900) !important;
    color: mat.get-color-from-palette($nx-dark-info-palette, '900-contrast') !important;
}