@use '/src/scss/angular-materials/nextech-palettes/bw-common-palettes.scss' as *;
@use '/src/scss/custom.scss' as *;

 .date-filter-option>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
    padding: 16px 20px;
}

 .reset {
    color: $nx-info !important;
    font-weight: 680 !important;
}

 .mat-datepicker-content {
    display: flex !important;
    flex-direction: row-reverse;
}

 .date-filter-option>div:nth-child(2) {
    padding: 0 0 20px 10px;
}

 .mat-datepicker-content-container>.mat-calendar {
    height: auto !important;
}

 .mat-calendar-header button span {
    font-weight: bold;
}

.date-filter-option button {
    color: $nx-black;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
    border: 0;
    background: unset;
    font-family: 'Open Sans', sans-serif;
}


 mat-datepicker-content.mat-datepicker-content.ng-tns-c116-5.ng-trigger.ng-trigger-transformPanel.mat-primary.ng-star-inserted {
    display: flex;
    flex-direction: row-reverse !important;
}

 .date-filter-option {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
}

.mat-calendar {
    border-left: solid 1px $nx-light-grey;
}

.mat-datepicker-actions {
    align-items: unset !important;
}

.reset-button {
    margin-top: 8em;
    background-color: unset;
    border: unset;
    text-align: left;
    color: $nx-info !important;
}


.mat-datepicker-content-container {
    flex-direction: row-reverse !important;
}

.date-action-button-desktop {
    display: flex;
    gap: 5px;
    margin-top: 6em;
    color: $nx-info !important;
    flex-direction: column;
}


@media only screen and (max-width: 768px) {
    .mat-datepicker-content-container {
        flex-direction: unset;
    }
}
@media only screen and (max-width: 560px) {
    .mat-datepicker-content-container {
        flex-direction: unset;
    }

    .date-filter-option button {
        flex-direction: row !important;
        gap: 5px;
        margin-top: unset !important;
    }
}
@media only screen and (max-width: 480px) {
    .mat-datepicker-content-container {
        flex-direction: unset;
    }
    .date-filter-option {
        display: none !important;
    }
}
