@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?z4ux17');
  src:  url('fonts/icomoon.eot?z4ux17#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?z4ux17') format('truetype'),
    url('fonts/icomoon.woff?z4ux17') format('woff'),
    url('fonts/icomoon.svg?z4ux17#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-navigate-next:before {
  content: "\e916";
}
.icon-chevron-left:before {
  content: "\e917";
}
.icon-arrow-back-ios:before {
  content: "\e918";
}
.icon-arrow-forward-ios:before {
  content: "\e919";
}
.icon-arrow-forward:before {
  content: "\e91a";
}
.icon-arrow-back:before {
  content: "\e91b";
}
.icon-logout-2:before {
  content: "\e915";
}
.icon-universal-currency-alt:before {
  content: "\e914";
}
.icon-pie-chart-2:before {
  content: "\e913";
}
.icon-chevron-right:before {
  content: "\e912";
}
.icon-analytics:before {
  content: "\e900";
}
.icon-arrow-split:before {
  content: "\e901";
}
.icon-bar-chart:before {
  content: "\e902";
}
.icon-campaign:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e904";
}
.icon-delete:before {
  content: "\e905";
}
.icon-done:before {
  content: "\e906";
}
.icon-filter:before {
  content: "\e907";
}
.icon-group:before {
  content: "\e908";
}
.icon-home-2:before {
  content: "\e909";
}
.icon-mail:before {
  content: "\e90a";
}
.icon-menu:before {
  content: "\e90b";
}
.icon-notifications:before {
  content: "\e90c";
}
.icon-open:before {
  content: "\e90d";
}
.icon-person:before {
  content: "\e90e";
}
.icon-refresh-2:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e910";
}
.icon-settings-2:before {
  content: "\e911";
}
.icon-uam_pm:before {
  content: "\e920";
}
